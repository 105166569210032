import React from "react";
import Products from "../../../component/home/products";
import HowItWorks from "../../../component/home/HowItWorks";
import ApplicationProcess from "../../../component/home/ApplicationProcess";
import FAQ from "../../../component/home/FAQ";
import { Seo } from "../../../component/SEO";
import { parseDomain, ParseResultType } from "parse-domain";

function HomePage() {
  const SKIN = process.env.NEXT_PUBLIC_REACT_APP_SKIN;
  let domainName = "USA-taxID";
  const parseResult = parseDomain(typeof window !== 'undefined' && window.location.hostname ? window.location.hostname : '');
  if (parseResult.type === ParseResultType.Listed) {
    const { domain } = parseResult;
    domainName = domain;
  }
  return (
    <div className="home">
      {domainName === "etax-filing" ?
        <Seo
          title={`EIN Application`}
          description=""
        /> :
        <Seo
          title={`${SKIN === "taxid" || SKIN === "taxid-ein" ? "Taxid" : "USA Tax ID"} | Home`}
          description=""
        />
      }
        <div className="products-wrapper">
        <div className="content">
          {SKIN === "taxid" || SKIN === "taxid-ein" ? (
            <React.Fragment>
              <div className="top-text">
                <h3>Online Application for {domainName!=="businesstin" && "Official"} EIN/Tax ID</h3>
                <hr style={{ height: "0.5px", background: "#282828" }} />
                <div className="subtitle">
                  Receive Your Official EIN Today
                </div>
                <h4
                  className="headerGray"
                  style={{
                    color: "#282828",
                    fontWeight: "600",
                    paddingRight: "10px",
                  }}
                >
                  <span style={{ color: "#273A7C" }}> EIN Assistant:</span>{" "}
                  Select Your Entity Type Below
                </h4>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <h3 style={{ color: "#101661" }}>
                Online Application for Official EIN/Tax ID
              </h3>
              <hr />
              <h4 style={{ textAlign: "center", color: "#101661" }}>
                Receive Your Official EIN Today
              </h4>
              <h4 className="headerGray">Select Your Entity Type Below</h4>
            </React.Fragment>
          )}
          <Products />
          {SKIN === "taxid" || SKIN === "taxid-ein" ? (
            <div />
          ) : (
            <p style={{ marginTop: "20px", width: "100%" }}>
              An Employer Identification Numbers (EIN) or Tax Identification
              Number (Tax ID, or TIN) is required for those who want to form a
              business, create a Trust or an Estate in the United States.
            </p>
          )}
        </div>
      </div>

      {SKIN === "taxid" || SKIN === "taxid-ein" ? (
        <div />
      ) : (
        <React.Fragment>
          <div className="blue-line">
            <h4>Start the EIN Filing Process</h4>
          </div>
          <HowItWorks />
          <div className="blue-line">
            <h4>EIN Application Process Explained</h4>
          </div>
          <ApplicationProcess />
        </React.Fragment>
      )}
      <div className="blue-line">
        {SKIN === "taxid" || SKIN === "taxid-ein" ? (
          <h4>Frequently Asked Questions</h4>
        ) : (
          <h4>Frequently Asked Questions About EINs</h4>
        )}
      </div>
      {SKIN === "taxid" || SKIN === "taxid-ein" ? <div className="blue-rectangle"></div> : null}
      <FAQ />
    </div>
  );
}

export default HomePage;
